var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "d-flex flex-column justify-center align-center fill-height"
    },
    [
      _vm.isRegisteringOrAccepting === false
        ? _c(
            "v-card",
            { attrs: { "max-width": "500" } },
            [
              _c(
                "v-card-title",
                [
                  _c("v-icon", { staticClass: "mr-1" }, [_vm._v("mail")]),
                  _vm._v("Invitation")
                ],
                1
              ),
              _vm.validInvitation !== false && _vm.recommendedAction !== "None"
                ? _c(
                    "v-card-text",
                    { staticClass: "black--text" },
                    [
                      _vm._v(
                        " You have received invitation(s) to Nuvolos. Please review the grants and continue if you wish to accept. "
                      ),
                      _c(
                        "div",
                        { staticClass: "mt-5 d-flex flex-column" },
                        [
                          _c(
                            "span",
                            {
                              staticClass:
                                "primary--text caption text-uppercase"
                            },
                            [_vm._v("review grants")]
                          ),
                          _c("v-divider")
                        ],
                        1
                      ),
                      _c(
                        "v-expansion-panels",
                        {
                          staticClass: "mt-3 flat-expansion-panel",
                          attrs: { hover: "" }
                        },
                        [
                          _c(
                            "v-expansion-panel",
                            { staticClass: "elevation-0" },
                            [
                              _c("v-expansion-panel-header", [
                                _vm._v(_vm._s(_vm.roles.length) + " grants")
                              ]),
                              _c(
                                "v-expansion-panel-content",
                                [
                                  _c(
                                    "v-container",
                                    {
                                      attrs: { "grid-list-md": "", fluid: "" }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex flex-column flex-wrap"
                                        },
                                        _vm._l(_vm.roles, function(r, index) {
                                          return _c(
                                            "div",
                                            { key: index, staticClass: "mb-1" },
                                            [
                                              _c(
                                                "v-card",
                                                {
                                                  attrs: {
                                                    tile: "",
                                                    outlined: ""
                                                  }
                                                },
                                                [
                                                  _c("v-card-title", [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "subtitle-1 primary--text"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "INVITATION " +
                                                            _vm._s(index + 1)
                                                        )
                                                      ]
                                                    )
                                                  ]),
                                                  _c(
                                                    "v-card-text",
                                                    {
                                                      staticClass:
                                                        "text--primary"
                                                    },
                                                    _vm._l(
                                                      _vm.user_pretty(r),
                                                      function(value, name) {
                                                        return _c(
                                                          "div",
                                                          { key: name },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "text-truncate",
                                                                staticStyle: {
                                                                  display:
                                                                    "block"
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "font-weight-bold primary--text"
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        name
                                                                      )
                                                                    )
                                                                  ]
                                                                ),
                                                                _vm._v(
                                                                  " : " +
                                                                    _vm._s(
                                                                      value
                                                                    ) +
                                                                    " "
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    0
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        }),
                                        0
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm.emailIsRegistered !== null
                        ? _c("div", [
                            !_vm.isLoggedIn && !_vm.emailIsRegistered
                              ? _c(
                                  "div",
                                  [
                                    _c("v-divider", { staticClass: "my-2" }),
                                    _c(
                                      "ask-about-account",
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "my-2 font-weight-bold"
                                          },
                                          [_vm._v("Account creation options")]
                                        ),
                                        _c("institution-login", [
                                          _vm.createNewAccount
                                            ? _c("div", [
                                                _vm.recommendedAction ===
                                                _vm.recommendedActions
                                                  .SIGN_UP_NEW_USER
                                                  ? _c(
                                                      "div",
                                                      [
                                                        _c(
                                                          "the-email-signup-option",
                                                          {
                                                            attrs: {
                                                              componentSpecificProps: {
                                                                userEmail:
                                                                  _vm.email
                                                              }
                                                            }
                                                          }
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                _vm.recommendedAction ===
                                                _vm.recommendedActions
                                                  .SIGN_UP_WITH_EXISTING_MIDPOINT_ACCOUNT
                                                  ? _c(
                                                      "div",
                                                      [
                                                        _c(
                                                          "the-upgrade-to-nuvolos-option",
                                                          {
                                                            attrs: {
                                                              componentSpecificProps: {
                                                                userEmail:
                                                                  _vm.email
                                                              }
                                                            }
                                                          }
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e()
                                              ])
                                            : _c(
                                                "div",
                                                [
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      attrs: {
                                                        text: "",
                                                        block: ""
                                                      },
                                                      on: {
                                                        click:
                                                          _vm.setCreateNewAccount
                                                      }
                                                    },
                                                    [_vm._v("E-mail address")]
                                                  )
                                                ],
                                                1
                                              )
                                        ])
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              : _vm.nuvolosEmail.toLowerCase() ===
                                  _vm.email.toLowerCase() ||
                                (_vm.isLoggedIn && !_vm.emailIsRegistered)
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "text-center caption",
                                    attrs: {
                                      wrap: "",
                                      "justify-center": "",
                                      "align-center": ""
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " You are logged in and can continue to accept the invitation. "
                                    ),
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass:
                                          "mt-4 mb-7 accept-with-current-user",
                                        attrs: {
                                          color: "secondary",
                                          to: {
                                            name: "invitation-check-user",
                                            params: {
                                              invid: _vm.$route.params.invid
                                            }
                                          },
                                          block: ""
                                        }
                                      },
                                      [_vm._v("Continue")]
                                    )
                                  ],
                                  1
                                )
                              : _vm.isLoggedIn
                              ? _c(
                                  "div",
                                  { staticClass: "mt-2" },
                                  [
                                    _vm._v(
                                      " The invitation seems to correspond to a different e-mail. Please "
                                    ),
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass: "my-4",
                                        attrs: {
                                          color: "secondary",
                                          block: ""
                                        },
                                        on: { click: _vm.logoutAndReturn }
                                      },
                                      [_vm._v("log out")]
                                    ),
                                    _vm._v(
                                      " from the current account and then return to this page. "
                                    )
                                  ],
                                  1
                                )
                              : _c(
                                  "div",
                                  { staticClass: "mt-2" },
                                  [
                                    _vm._v(
                                      " The e-mail for this invitation is already registered. After clicking on the button below, use the following e-mail to sign in: "
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "text-center my-4 font-weight-bold"
                                      },
                                      [_vm._v(" " + _vm._s(_vm.email) + " ")]
                                    ),
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: {
                                          color: "secondary",
                                          block: ""
                                        },
                                        on: { click: _vm.loginAndReturn }
                                      },
                                      [_vm._v("Next")]
                                    )
                                  ],
                                  1
                                )
                          ])
                        : _c("div", [
                            _vm._v(
                              " Checking if e-mail is registered already... "
                            )
                          ])
                    ],
                    1
                  )
                : _c("v-card-text", [
                    _vm._v(
                      "We are sorry but this invitation is no longer valid. "
                    ),
                    _c(
                      "div",
                      { staticClass: "d-flex justify-center mt-2" },
                      [
                        _c(
                          "v-btn",
                          {
                            attrs: {
                              to: { name: "login" },
                              color: "secondary",
                              text: ""
                            }
                          },
                          [_vm._v("Sign in to Nuvolos")]
                        )
                      ],
                      1
                    )
                  ])
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }